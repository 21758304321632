<script setup lang="ts">
import type { NuxtError } from '#app';

const { error } = defineProps({
    error: {
        type: Object as PropType<NuxtError>,
        required: true,
    },
});

useSeoMeta({ title: () => `${error.statusMessage} - Fleequid Admin` });
</script>

<template>
    <NuxtLayout name="default">
        <UDashboardPage>
            <UDashboardPanel grow>
                <BaseDashboardNavbar :title="error.statusMessage || error.name" />
                <UDashboardPanelContent>
                    <UPageError :status="error.statusCode" :name="error.statusMessage" :message="error.message" />
                </UDashboardPanelContent>
            </UDashboardPanel>
        </UDashboardPage>
    </NuxtLayout>
</template>
