import { default as auction_45plannerQ2NEMWwvI6Meta } from "/opt/render/project/src/apps/admin/pages/auction-planner.vue?macro=true";
import { default as auto_45bidsiGAcWi8rCWMeta } from "/opt/render/project/src/apps/admin/pages/auctions/[id]/auto-bids.vue?macro=true";
import { default as bidsViSnK21AhHMeta } from "/opt/render/project/src/apps/admin/pages/auctions/[id]/bids.vue?macro=true";
import { default as historyvFBd5aIKLaMeta } from "/opt/render/project/src/apps/admin/pages/auctions/[id]/history.vue?macro=true";
import { default as indexr1W1kxnOKoMeta } from "/opt/render/project/src/apps/admin/pages/auctions/[id]/index.vue?macro=true";
import { default as relists0Zwf6A0AikMeta } from "/opt/render/project/src/apps/admin/pages/auctions/[id]/relists.vue?macro=true";
import { default as schedulej8NT5Ecgd8Meta } from "/opt/render/project/src/apps/admin/pages/auctions/[id]/schedule.vue?macro=true";
import { default as settlementVPqWR6EJ0eMeta } from "/opt/render/project/src/apps/admin/pages/auctions/[id]/settlement.vue?macro=true";
import { default as _91id_9312dxNJZKVwMeta } from "/opt/render/project/src/apps/admin/pages/auctions/[id].vue?macro=true";
import { default as createu2GBOxvLUpMeta } from "/opt/render/project/src/apps/admin/pages/auctions/create.vue?macro=true";
import { default as indexEdbLYJywpbMeta } from "/opt/render/project/src/apps/admin/pages/auctions/index.vue?macro=true";
import { default as _91id_938qkGm9x2BlMeta } from "/opt/render/project/src/apps/admin/pages/brands/[id].vue?macro=true";
import { default as createZPeiZykfAiMeta } from "/opt/render/project/src/apps/admin/pages/brands/create.vue?macro=true";
import { default as indexEmrN8vrzn2Meta } from "/opt/render/project/src/apps/admin/pages/brands/index.vue?macro=true";
import { default as detailsUnVub78F0zMeta } from "/opt/render/project/src/apps/admin/pages/bus-assets/[id]/details.vue?macro=true";
import { default as documentsilwao6lB6tMeta } from "/opt/render/project/src/apps/admin/pages/bus-assets/[id]/documents.vue?macro=true";
import { default as images3B3IAT58scMeta } from "/opt/render/project/src/apps/admin/pages/bus-assets/[id]/images.vue?macro=true";
import { default as indexDzaXyVyzPfMeta } from "/opt/render/project/src/apps/admin/pages/bus-assets/[id]/index.vue?macro=true";
import { default as _91id_93qqlVIBftgKMeta } from "/opt/render/project/src/apps/admin/pages/bus-assets/[id].vue?macro=true";
import { default as indexUjDj0CglLIMeta } from "/opt/render/project/src/apps/admin/pages/bus-assets/index.vue?macro=true";
import { default as detailse17rhFDiUHMeta } from "/opt/render/project/src/apps/admin/pages/bus-dossiers/[id]/details.vue?macro=true";
import { default as documentsqjz6rXB1KqMeta } from "/opt/render/project/src/apps/admin/pages/bus-dossiers/[id]/documents.vue?macro=true";
import { default as imagestwNnNGH5MCMeta } from "/opt/render/project/src/apps/admin/pages/bus-dossiers/[id]/images.vue?macro=true";
import { default as indexeefOzkCxTeMeta } from "/opt/render/project/src/apps/admin/pages/bus-dossiers/[id]/index.vue?macro=true";
import { default as _91id_93moWoFhqXUnMeta } from "/opt/render/project/src/apps/admin/pages/bus-dossiers/[id].vue?macro=true";
import { default as createYet1jbOl0nMeta } from "/opt/render/project/src/apps/admin/pages/bus-dossiers/create.vue?macro=true";
import { default as indexx2mkgIQI67Meta } from "/opt/render/project/src/apps/admin/pages/bus-dossiers/index.vue?macro=true";
import { default as _91id_93DXDzeIwscHMeta } from "/opt/render/project/src/apps/admin/pages/bus-factory-attributes/[id].vue?macro=true";
import { default as createDIDSVeiGFZMeta } from "/opt/render/project/src/apps/admin/pages/bus-factory-attributes/create.vue?macro=true";
import { default as indexgQBy5BXqfMMeta } from "/opt/render/project/src/apps/admin/pages/bus-factory-attributes/index.vue?macro=true";
import { default as documentsLc5DX0dIMHMeta } from "/opt/render/project/src/apps/admin/pages/companies/[id]/documents.vue?macro=true";
import { default as indexEFB8XFRhH4Meta } from "/opt/render/project/src/apps/admin/pages/companies/[id]/index.vue?macro=true";
import { default as _91id_93NAjRIW1X67Meta } from "/opt/render/project/src/apps/admin/pages/companies/[id].vue?macro=true";
import { default as createM8AUaXB2rkMeta } from "/opt/render/project/src/apps/admin/pages/companies/create.vue?macro=true";
import { default as index9G0GTm70ivMeta } from "/opt/render/project/src/apps/admin/pages/companies/index.vue?macro=true";
import { default as indexzaCC7vTIAxMeta } from "/opt/render/project/src/apps/admin/pages/index.vue?macro=true";
import { default as loginy3TDq9ePx9Meta } from "/opt/render/project/src/apps/admin/pages/login.vue?macro=true";
import { default as _91id_93R7JkT0adzHMeta } from "/opt/render/project/src/apps/admin/pages/models/[id].vue?macro=true";
import { default as create26Xp5aCuV9Meta } from "/opt/render/project/src/apps/admin/pages/models/create.vue?macro=true";
import { default as index82vVCpCAqCMeta } from "/opt/render/project/src/apps/admin/pages/models/index.vue?macro=true";
import { default as password_45resetkHWtZvGt5lMeta } from "/opt/render/project/src/apps/admin/pages/password-reset.vue?macro=true";
import { default as request_45password_45resetnECDznY8ERMeta } from "/opt/render/project/src/apps/admin/pages/request-password-reset.vue?macro=true";
import { default as settingsScZH7MN4faMeta } from "/opt/render/project/src/apps/admin/pages/settings.vue?macro=true";
import { default as actionsNsMrKf7AQiMeta } from "/opt/render/project/src/apps/admin/pages/users/[id]/actions.vue?macro=true";
import { default as indexT7bP3Z3Or0Meta } from "/opt/render/project/src/apps/admin/pages/users/[id]/index.vue?macro=true";
import { default as _91id_93MPlLpzUCK9Meta } from "/opt/render/project/src/apps/admin/pages/users/[id].vue?macro=true";
import { default as createSLeOQh9xZGMeta } from "/opt/render/project/src/apps/admin/pages/users/create.vue?macro=true";
import { default as index26Xi6Emp1dMeta } from "/opt/render/project/src/apps/admin/pages/users/index.vue?macro=true";
import { default as _91id_937x8yvnRWOVMeta } from "/opt/render/project/src/apps/admin/pages/versions/[id].vue?macro=true";
import { default as create2oqSwadu08Meta } from "/opt/render/project/src/apps/admin/pages/versions/create.vue?macro=true";
import { default as indexj7f8SDEpgDMeta } from "/opt/render/project/src/apps/admin/pages/versions/index.vue?macro=true";
export default [
  {
    name: "auction-planner",
    path: "/auction-planner",
    component: () => import("/opt/render/project/src/apps/admin/pages/auction-planner.vue")
  },
  {
    name: _91id_9312dxNJZKVwMeta?.name,
    path: "/auctions/:id()",
    component: () => import("/opt/render/project/src/apps/admin/pages/auctions/[id].vue"),
    children: [
  {
    name: "auctions-id-auto-bids",
    path: "auto-bids",
    component: () => import("/opt/render/project/src/apps/admin/pages/auctions/[id]/auto-bids.vue")
  },
  {
    name: "auctions-id-bids",
    path: "bids",
    component: () => import("/opt/render/project/src/apps/admin/pages/auctions/[id]/bids.vue")
  },
  {
    name: "auctions-id-history",
    path: "history",
    component: () => import("/opt/render/project/src/apps/admin/pages/auctions/[id]/history.vue")
  },
  {
    name: "auctions-id",
    path: "",
    component: () => import("/opt/render/project/src/apps/admin/pages/auctions/[id]/index.vue")
  },
  {
    name: "auctions-id-relists",
    path: "relists",
    component: () => import("/opt/render/project/src/apps/admin/pages/auctions/[id]/relists.vue")
  },
  {
    name: "auctions-id-schedule",
    path: "schedule",
    component: () => import("/opt/render/project/src/apps/admin/pages/auctions/[id]/schedule.vue")
  },
  {
    name: "auctions-id-settlement",
    path: "settlement",
    component: () => import("/opt/render/project/src/apps/admin/pages/auctions/[id]/settlement.vue")
  }
]
  },
  {
    name: "auctions-create",
    path: "/auctions/create",
    component: () => import("/opt/render/project/src/apps/admin/pages/auctions/create.vue")
  },
  {
    name: "auctions",
    path: "/auctions",
    component: () => import("/opt/render/project/src/apps/admin/pages/auctions/index.vue")
  },
  {
    name: "brands-id",
    path: "/brands/:id()",
    component: () => import("/opt/render/project/src/apps/admin/pages/brands/[id].vue")
  },
  {
    name: "brands-create",
    path: "/brands/create",
    component: () => import("/opt/render/project/src/apps/admin/pages/brands/create.vue")
  },
  {
    name: "brands",
    path: "/brands",
    component: () => import("/opt/render/project/src/apps/admin/pages/brands/index.vue")
  },
  {
    name: _91id_93qqlVIBftgKMeta?.name,
    path: "/bus-assets/:id()",
    component: () => import("/opt/render/project/src/apps/admin/pages/bus-assets/[id].vue"),
    children: [
  {
    name: "bus-assets-id-details",
    path: "details",
    component: () => import("/opt/render/project/src/apps/admin/pages/bus-assets/[id]/details.vue")
  },
  {
    name: "bus-assets-id-documents",
    path: "documents",
    component: () => import("/opt/render/project/src/apps/admin/pages/bus-assets/[id]/documents.vue")
  },
  {
    name: "bus-assets-id-images",
    path: "images",
    component: () => import("/opt/render/project/src/apps/admin/pages/bus-assets/[id]/images.vue")
  },
  {
    name: "bus-assets-id",
    path: "",
    component: () => import("/opt/render/project/src/apps/admin/pages/bus-assets/[id]/index.vue")
  }
]
  },
  {
    name: "bus-assets",
    path: "/bus-assets",
    component: () => import("/opt/render/project/src/apps/admin/pages/bus-assets/index.vue")
  },
  {
    name: _91id_93moWoFhqXUnMeta?.name,
    path: "/bus-dossiers/:id()",
    component: () => import("/opt/render/project/src/apps/admin/pages/bus-dossiers/[id].vue"),
    children: [
  {
    name: "bus-dossiers-id-details",
    path: "details",
    component: () => import("/opt/render/project/src/apps/admin/pages/bus-dossiers/[id]/details.vue")
  },
  {
    name: "bus-dossiers-id-documents",
    path: "documents",
    component: () => import("/opt/render/project/src/apps/admin/pages/bus-dossiers/[id]/documents.vue")
  },
  {
    name: "bus-dossiers-id-images",
    path: "images",
    component: () => import("/opt/render/project/src/apps/admin/pages/bus-dossiers/[id]/images.vue")
  },
  {
    name: "bus-dossiers-id",
    path: "",
    component: () => import("/opt/render/project/src/apps/admin/pages/bus-dossiers/[id]/index.vue")
  }
]
  },
  {
    name: "bus-dossiers-create",
    path: "/bus-dossiers/create",
    component: () => import("/opt/render/project/src/apps/admin/pages/bus-dossiers/create.vue")
  },
  {
    name: "bus-dossiers",
    path: "/bus-dossiers",
    component: () => import("/opt/render/project/src/apps/admin/pages/bus-dossiers/index.vue")
  },
  {
    name: "bus-factory-attributes-id",
    path: "/bus-factory-attributes/:id()",
    component: () => import("/opt/render/project/src/apps/admin/pages/bus-factory-attributes/[id].vue")
  },
  {
    name: "bus-factory-attributes-create",
    path: "/bus-factory-attributes/create",
    component: () => import("/opt/render/project/src/apps/admin/pages/bus-factory-attributes/create.vue")
  },
  {
    name: "bus-factory-attributes",
    path: "/bus-factory-attributes",
    component: () => import("/opt/render/project/src/apps/admin/pages/bus-factory-attributes/index.vue")
  },
  {
    name: _91id_93NAjRIW1X67Meta?.name,
    path: "/companies/:id()",
    component: () => import("/opt/render/project/src/apps/admin/pages/companies/[id].vue"),
    children: [
  {
    name: "companies-id-documents",
    path: "documents",
    component: () => import("/opt/render/project/src/apps/admin/pages/companies/[id]/documents.vue")
  },
  {
    name: "companies-id",
    path: "",
    component: () => import("/opt/render/project/src/apps/admin/pages/companies/[id]/index.vue")
  }
]
  },
  {
    name: "companies-create",
    path: "/companies/create",
    component: () => import("/opt/render/project/src/apps/admin/pages/companies/create.vue")
  },
  {
    name: "companies",
    path: "/companies",
    component: () => import("/opt/render/project/src/apps/admin/pages/companies/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/render/project/src/apps/admin/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginy3TDq9ePx9Meta || {},
    component: () => import("/opt/render/project/src/apps/admin/pages/login.vue")
  },
  {
    name: "models-id",
    path: "/models/:id()",
    component: () => import("/opt/render/project/src/apps/admin/pages/models/[id].vue")
  },
  {
    name: "models-create",
    path: "/models/create",
    component: () => import("/opt/render/project/src/apps/admin/pages/models/create.vue")
  },
  {
    name: "models",
    path: "/models",
    component: () => import("/opt/render/project/src/apps/admin/pages/models/index.vue")
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: password_45resetkHWtZvGt5lMeta || {},
    component: () => import("/opt/render/project/src/apps/admin/pages/password-reset.vue")
  },
  {
    name: "request-password-reset",
    path: "/request-password-reset",
    meta: request_45password_45resetnECDznY8ERMeta || {},
    component: () => import("/opt/render/project/src/apps/admin/pages/request-password-reset.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/opt/render/project/src/apps/admin/pages/settings.vue")
  },
  {
    name: _91id_93MPlLpzUCK9Meta?.name,
    path: "/users/:id()",
    component: () => import("/opt/render/project/src/apps/admin/pages/users/[id].vue"),
    children: [
  {
    name: "users-id-actions",
    path: "actions",
    component: () => import("/opt/render/project/src/apps/admin/pages/users/[id]/actions.vue")
  },
  {
    name: "users-id",
    path: "",
    component: () => import("/opt/render/project/src/apps/admin/pages/users/[id]/index.vue")
  }
]
  },
  {
    name: "users-create",
    path: "/users/create",
    component: () => import("/opt/render/project/src/apps/admin/pages/users/create.vue")
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/opt/render/project/src/apps/admin/pages/users/index.vue")
  },
  {
    name: "versions-id",
    path: "/versions/:id()",
    component: () => import("/opt/render/project/src/apps/admin/pages/versions/[id].vue")
  },
  {
    name: "versions-create",
    path: "/versions/create",
    component: () => import("/opt/render/project/src/apps/admin/pages/versions/create.vue")
  },
  {
    name: "versions",
    path: "/versions",
    component: () => import("/opt/render/project/src/apps/admin/pages/versions/index.vue")
  }
]